import { render, staticRenderFns } from "./Capital.vue?vue&type=template&id=de8824fe&scoped=true&"
import script from "./Capital.vue?vue&type=script&lang=js&"
export * from "./Capital.vue?vue&type=script&lang=js&"
import style0 from "./Capital.vue?vue&type=style&index=0&id=de8824fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de8824fe",
  null
  
)

export default component.exports