<template>
  <div class="top">
    <img class="top-img"/>
    <div class="my-capital">
      <div class="capital-title">
        {{ wallet.myCapital }}（$）
        <!-- 更多按钮 -->
        <img
          class="more-icon"
        />
        <!-- 详情按钮 -->
        <!-- <div class="detail-btn" @click="handleToDetail">{{ detail }}</div> -->
         <!-- 连接钱包  -->
         <!-- <div class="detail-btn connect-btn" @click="handleToWallet">{{myAcount || 'Connect'}}</div> -->
      </div>
      <div class="money-box" v-show="true">
        <img class="moneyBg"/>
        <div class="money-item">
          <div class="symbol">$</div>
          <div class="amount">23456.12</div>
          <img
            class="alert-icon"
          />
          <van-icon name="arrow" class="arrow" />
        </div>
      </div>
      <div class="button-group">
        <!-- 转账 -->
        <div class="button" v-if="false" @click="$gbUtils.handleToPage(`/transfer?title=${wallet.transfer}`)">
          <img class="button-img"/>
          <div class="button-text">{{ wallet.transfer }}</div>
        </div>
        <!-- 收款 -->
        <div  class="button" :class="{'button-en': mbLang === 'EN'} " @click=" $gbUtils.handleToPage( `/receive?title=${wallet.receive}`) ">
          <img  class="button-img"/>
          <div class="button-text">{{ wallet.receive }}</div>
        </div>
      </div>
    </div>
    <div class="lp-list" v-if="wallet.lpList && wallet.lpList.length > 0">
      <div
        class="lp-item"
        v-for="item in wallet.lpList"
        :key="item.id"
        @click="$gbUtils.handleToPage(`${item.path}?title=${item.text}`)"
      >
        <img class="lp-icon" :src="item.icon"/>
        <div class="lp-text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    wallet: {
      default () {
        return ''
      }
    }
  },
  computed: {
    ...mapState(['myAcount', 'mbLang']),
    detail () {
      return this.$t('common.detail')
    }
  },
  methods: {
    handleToDetail () {
      this.$emit('handleToDetail')
    },
    async handleToWallet () {
      const resp = await this.$web3.connectWallet()
      console.log(resp)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 316px;
  position: relative;
  .top-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .my-capital {
    position: relative;
    padding-top: 50px;
    box-sizing: border-box;
    .capital-title {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      color: #a65f33;
      position: relative;
      .more-icon {
        position: absolute;
        height: 13px;
        top: 5px;
        right: 25px;
      }
      .detail-btn {
        position: absolute;
        height: 13px;
        top: 40px;
        right: 25px;
      }
      .connect-btn {
        max-width: 200px;
        top: 70px;
        height: 20px;
        background: #fff;
        border-radius: 20px;
        padding: 2px 3px;
        overflow: hidden;
      }
    }
    .money-box {
      width: 228px;
      // display: inline-block;
      height: 50px;
      line-height: 150px;
      display: flex;
      position: relative;
      margin: 5px auto;
      opacity: 0;
      .moneyBg {
        position: absolute;
        height: 100%;
        width: 100%;
      }
      .money-item {
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: normal;
        color: rgba(250, 248, 203, 0.59);
        padding: 0 5px 0 20px;
        .symbol {
          opacity: 0.93;
          -webkit-text-stroke: 1px #000000;
        }
        .amount {
          opacity: 0.93;
          -webkit-text-stroke: 1px #000000;
        }
        .alert-icon {
          height: 18px;
          margin-left: 5px;
        }
        .arrow {
          font-size: 13px;
          font-weight: 500;
          color: #ffe3bc;
          line-height: 20px;
          // text-stroke: unset;
        }
      }
    }
    .button-group {
      width: 228px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 20px auto 0 auto;
      .button {
        width: 80px;
        height: 32px;
        line-height: 32px;
        // text-align: center;
        position: relative;
        z-index: 1;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        // -webkit-text-stroke: 1px #000000;
        box-sizing: border-box;
        .button-img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          z-index: -1;
        }
        .button-text {
          position: absolute;
          top: 7px;
          right: 15px;
        }
        &.button-en{
          width: 100px;
          .button-text {
          position: absolute;
          top: 5px;
          right: 10px;
        }
        }
      }
    }
  }
  .lp-list {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 50px;
    .lp-item {
      flex: 1;
      text-align: center;
      .lp-icon {
        width: 25px;
        height: 25px;
      }
      .lp-text {
        font-size: 14px;
        font-weight: 500;
        color: #d7af7e;
        line-height: 18px;
        // -webkit-text-stroke: 1px #331f0f;
        // text-stroke: 3px #331F0F;
      }
    }
  }
}
</style>
