<template>
  <div class="wallet-page tab-page">
    <Capital :wallet="wallet" @handleToDetail="handleToDetail"></Capital>
    <div class="capital-box">
      <div class="capital-detail">{{ wallet.capitalDetail }}</div>
      <!-- @click="$gbUtils.handleToPage( `/tokenDetail?id=${item.id}`)" -->
      <div class="capital-item"
        :style="{ backgroundImage: `url(${coinBg})`, backgroundSize: 'cover' }" v-for="item in coinList" :key="item.id">
        <div class="coin-left">
          <img :src="item.icon" class="coin-icon" />
          <span>{{ item.coinName }}</span>
        </div>
        <div class="coin-right">
          <div class="coin-num">{{ item.num }}</div>
          <div class="coin-amount" v-if="false">${{ item.amount }}</div>
        </div>
      </div>
    </div>
    <!-- #ifdef APP-PLUS -->
    <BottomTabBar :selected="2" />
    <!-- #endif -->
  </div>
</template>

<script>
import BottomTabBar from '@/components/common/BottomTabBar.vue'
import Capital from './components/Capital.vue'
// import contractsInfo from '../../utils/contractsInfo' // 正式合约文件
import contractsInfo from '../../utils/contractsInfo1' // 测试合约文件

export default {
  components: {
    Capital,
    BottomTabBar
  },
  data () {
    return {
      tokenGep: contractsInfo.tokenGEPContract, // gep token信息
      tokenUsdt: contractsInfo.tokenUSDTContract, // usdt token信息
      coinList: [
        {
          id: 1,
          icon: '',
          coinName: 'GEP',
          num: '0',
          amount: '0'
        },
        {
          id: 2,
          icon: '',
          coinName: 'USDT',
          num: '0',
          amount: '0'
        },
        {
          id: 3,
          icon: '',
          coinName: 'BNB',
          num: '0',
          amount: '0'
        }
      ]
    }
  },
  computed: {
    wallet () {
      return this.$t('wallet')
    }
  },
  methods: {
    // 获取剩
    async getBalance () {
      const usdtResp = await this.$web3.balanceOf(this.tokenUsdt)
      const gepResp = await this.$web3.balanceOf(this.tokenGep)
      if (gepResp.success) {
        this.coinList[0].num = this.$gbUtils.formatBalance(gepResp.result) || 0
      }
      if (usdtResp.success) {
        this.coinList[1].num = this.$gbUtils.formatBalance(usdtResp.result) || 0
      }
      const bnbResp = await this.$web3.getBalance()
      if (bnbResp.success) {
        this.coinList[2].num = this.$gbUtils.formatBalance(bnbResp.result) || 0
      }
    },
    // 跳转钱包详情
    handleToDetail () {
      this.$router.push({ path: '/walletDetail', query: {} })
    }
  },
  mounted () {
    this.getBalance()
  }
}
</script>

<style lang="scss" scoped>
.wallet-page {
  min-height: 100vh;
  // 资金详情
  .capital-box {
    margin-top: 15px;
    padding: 0 10px;
    box-sizing: border-box;
    .capital-detail {
      font-size: 15px;
      font-weight: 500;
      color: #231b11;
      line-height: 36px;
      margin: 10px 0;
    }
    .capital-item {
      width: 100%;
      min-height: 50px;
      // opacity: 0.65;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      box-sizing: border-box;
      margin-top: 5px;
      .coin-left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #383632;
        .coin-icon {
          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
      }
      .coin-right {
        flex: 1;
        text-align: right;
        .coin-num {
          font-size: 13px;
          color: #10100f;
          // -webkit-text-stroke: 2px #F6D8B1;
          // text-stroke: 2px #F6D8B1;
        }
        .coin-amount {
          font-size: 12px;
          color: #7d593d;
          // -webkit-text-stroke: 2px #F6D8B1;
          // text-stroke: 2px #F6D8B1;
        }
      }
    }
  }
}
</style>
